/** @format **/
export const urlPath = process.env.REACT_APP_API;

/*-----Login Api-----*/
export const loginApi = '/login';
export const logOutApi = '/logout';
export const profileApi = '/profile';
export const UpdateProfileApi = '/profile';
export const cookieSanctum = '/sanctum/csrf-cookie';

/*----Forgot Api----*/
export const VerifyEmailApi = '/verify-email';
export const VerifyOTPApi = '/verify-otp';
export const ResetPasswordApi = '/reset-password';

/*----Users Api----*/
export const getUsersAPI = '/users';
export const postUsersAPI = '/users';
export const deleteUsersAPI = '/users';
export const viewUsersAPI = '/users';
export const updateUsersAPI = '/users';
export const getSearchUsersAPI = '/users';

/*---- Products -----*/
export const getProductAPI = '/products';
export const postProductAPI = '/products';
export const updateProductAPI = '/products';
export const deleteProductAPI = '/products';
export const viewProductAPI = '/products';

/*---- Orders -----*/
export const getBookingsAPI = '/booking';
export const postBookingsAPI = '/booking';
export const viewBookingsAPI = '/booking';
export const updateBookingsAPI = '/booking';
export const getMyBookingsAPI = '/mybooking';