const plans = [
    {
        name: "18 Month Home Natural Gas Plan",
        url: "https://www.constellation.com/solutions/for-your-home/residential-signup.html/?offerCode=ChooseEnergy&planID=12556&udcCodeGas=NICOR&zip=60175",
        unit: "¢/Therm",
        price: 0.499,
        supplier: {
            name: "Constellation",
            phone: "855-619-8095"
        },
        shortDescription: "",
        longDescription: "",
        category: "Natural Gas",
        isFixedRate: true,
        termLength: 18,
        tosUrl: "https://api.saveonenergy.cloud/document/ead8529b-696d-44b0-bcec-9d77a872e011?versionNum=244455"
    },
    {
        name: "12 Month Home Natural Gas Plan",
        url: "https://www.constellation.com/solutions/for-your-home/residential-signup.html/?offerCode=ChooseEnergy&planID=227&udcCodeGas=NICOR&zip=60175",
        unit: "¢/Therm",
        price: 0.549,
        supplier: {
            name: "Constellation",
            phone: "855-619-8095"
        },
        shortDescription: "7.95 CSF",
        longDescription: "",
        category: "Natural Gas",
        isFixedRate: true,
        termLength: 12,
        tosUrl: "https://api.saveonenergy.cloud/document/ead8529b-696d-44b0-bcec-9d77a872e011?versionNum=244453"
    },
    {
        name: "24 Month Home Natural Gas Plan",
        url: "https://www.constellation.com/solutions/for-your-home/residential-signup.html/?offerCode=ChooseEnergy&planID=12234&udcCodeGas=NICOR&zip=60175",
        unit: "¢/Therm",
        price: 0.519,
        supplier: {
            name: "Constellation",
            phone: "855-619-8095"
        },
        shortDescription: "7.95 CSF",
        longDescription: "",
        category: "Natural Gas",
        isFixedRate: true,
        termLength: 24,
        tosUrl: "https://api.saveonenergy.cloud/document/ead8529b-696d-44b0-bcec-9d77a872e011?versionNum=169386"
    },
    {
        name: "6 Month Home Natural Gas Plan",
        url: "https://www.constellation.com/solutions/for-your-home/residential-signup.html/?offerCode=ChooseEnergy&planID=652&udcCodeGas=NICOR&zip=60175",
        unit: "¢/Therm",
        price: 0.549,
        supplier: {
            name: "Constellation",
            phone: "855-619-8095"
        },
        shortDescription: "",
        longDescription: "",
        category: "Natural Gas",
        isFixedRate: true,
        termLength: 6,
        tosUrl: "https://api.saveonenergy.cloud/document/ead8529b-696d-44b0-bcec-9d77a872e011?versionNum=244454"
    }
]
export default plans;