/** @format **/

// React
import { useRoutes } from 'react-router-dom';

// DashboardApp
import DashboardApp from './pages/DashboardApp.jsx';
import PlansList from './pages/PlansList.jsx';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    { path: '/', element: <DashboardApp /> },
    { path: '/plans', element: <PlansList /> },
  ]);
}