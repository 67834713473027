/** @format */
// React Imports
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

// Mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Container } from '@mui/material';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

// Icons
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Components
import Page from '../layouts/components/common/Page';

const DashboardApp = () => {
  const [zipCode, setZipCode] = React.useState('');
  const [radioValue, setRadioValue] = React.useState('');
  const navigate = useNavigate();

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const handleFindSavingsClick = () => {
    if (zipCode === '60175') {
      navigate('/plans');
    } else {
      // Handle other cases, like showing an error or validation
      alert('Please enter a valid ZIP Code.');
    }
  };

  return (
    <Page title="Home">
      <Box className="banner_box">
        <Container>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5 }}>
            <Typography variant="h2">
              Compare energy providers and{' '}
              <b className="yellow_heading">find savings</b> today
            </Typography>

            <ul className="list_banner">
              <li>
                <CheckCircleOutlineIcon />
                <Typography variant="subtitle1">
                  Electricity & natural gas providers
                </Typography>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <Typography variant="subtitle1">Competitive rates</Typography>
              </li>
              <li>
                <CheckCircleOutlineIcon />
                <Typography variant="subtitle1">Secure checkout</Typography>
              </li>
            </ul>

            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }} className="zip_input">
              <TextField
              className='inputaa'
                type='number'
                sx={{ borderRadius: '30px' }}
                fullWidth
                placeholder="Enter ZIP Code"
                value={zipCode}
                onChange={handleZipCodeChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        className='input_button_banner'
                        onClick={handleFindSavingsClick}
                      >
                        Find Savings<ArrowForwardIcon />
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                <Typography variant="subtitle1">
                  Are you moving to a new address?
                </Typography>
                <FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fill: '#3b4da0',
                          },
                          '&.Mui-checked': {
                            color: 'rgb(59, 77, 160)',
                          },
                          '&.Mui-checked + .MuiFormControlLabel-label': {
                            color: 'rgb(59, 77, 160)',
                          },
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          '&.Mui-checked': {
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              backgroundColor: 'rgb(59, 77, 160)',
                              boxShadow: '0 0 0 1px #fff, 0 0 0 2px #3b4da0',
                              transform: 'translate(-50%, -50%)',
                            },
                          },
                        }}
                        checked={radioValue === 'yes'}
                        onChange={handleRadioChange}
                        value="yes"
                        name="radio-buttons"
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fill: '#3b4da0',
                          },
                          '&.Mui-checked': {
                            color: 'rgb(59, 77, 160)',
                          },
                          '&.Mui-checked + .MuiFormControlLabel-label': {
                            color: 'rgb(59, 77, 160)',
                          },
                          '&:hover': {
                            backgroundColor: 'transparent', 
                          },
                          '&.Mui-checked': {
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              width: 12,
                              height: 12,
                              borderRadius: '50%',
                              backgroundColor: 'rgb(59, 77, 160)',
                              boxShadow: '0 0 0 1px #fff, 0 0 0 2px #3b4da0',
                              transform: 'translate(-50%, -50%)',
                            },
                          },
                        }}
                        checked={radioValue === 'no'}
                        onChange={handleRadioChange}
                        value="no"
                        name="radio-buttons"
                      />
                    }
                    label="No"
                  />
                </FormControl>
              </Box>
            </Box>

          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default DashboardApp;