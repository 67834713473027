// material
import {
    Box,
    Card,
    Button,
    Container,
    Typography,
} from '@mui/material';

//Components
import Page from '../layouts/components/common/Page';
import PlansData from '../Data';

// Image
import Fire from '../assets/images/fire.svg';
import Constellation from '../assets/images/constellation.png';
import Clock from '../assets/images/clock.svg';
import Phone from '../assets/images/phone.svg';

const Flight = () => {

    return (
        <Page title="plans">
            <Container className="UserPagePadding">
                <Box sx={{ pt: 4, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h2 className='h2_tag'>All other plans</h2>
                </Box>
                {PlansData.map((row, index) => (
                    <Card key={index} className='cards_div'>
                        <Box className='cards_inner_div'>
                            <Box className='partition_main_div'>
                                <Box className='box_partition'>
                                    <Box className='fire_tag'>
                                        <img src={Fire} alt='fire' />
                                        <Typography variant='body'>Gas</Typography>
                                    </Box>
                                    <Typography variant='h2'>{row.name}</Typography>
                                    <img src={Constellation} alt='Constellation' className='company_logo' />
                                </Box>
                                <Box className='box_partition_2'>
                                    <Typography variant='body' className='fixed_rate'>
                                        <img src={Clock} alt='clock' />
                                        Fixed rate
                                    </Typography>
                                </Box>
                                <Box className='box_partition_3'>
                                    <Box>
                                        <Box className='price_term'>
                                            <Typography variant='h3'>Price per</Typography>
                                            <Typography variant='h5'>THERM</Typography>
                                        </Box>
                                        <Typography variant='h4'>{row.price}¢</Typography>
                                    </Box>
                                    <Box>
                                        <Box className='price_term'>
                                            <Typography variant='h3'>Term length</Typography>
                                            <Typography variant='h5'>FIXED RATE</Typography>
                                        </Box>
                                        <Typography variant='h4'>{row.termLength}mos.</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='card_button'>
                                <Button variant='contained' className='availability_button'>
                                    <a href={row.url} target="_blank" >Check availability</a>
                                </Button>
                                <Typography className='contact_button'>
                                    <img src={Phone} alt='Phone Icon' />
                                    {row.supplier.phone}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                ))}
            </Container>
        </Page>
    );
}

export default Flight;