/** @format */

import axios from "axios";
import toast from "react-hot-toast";
import { loginApi, profileApi, UpdateProfileApi, logOutApi } from "../api";
import Instance from "../axios";

const API_URL = process.env.REACT_APP_API;

const register = async (data) => {
	try {
		const response = await Instance.post(API_URL + "/users", data);
		return response;
	} catch (error) {
		throw error;
	}
};


const login = (email, password) => {
	return Instance.post(API_URL + loginApi, {
		email,
		password,
	})
		.then((response) => {
			if (response.data) {
				localStorage.setItem("user", JSON.stringify(response.data));
			}
			return response.data;
		})
		.catch((error) => {
			// console.log("login service", error)
			// toast.error(error.response.data.message);
		});
};

const logout = async () => {
	const response = await axios.get(API_URL + logOutApi);
	localStorage.removeItem('user');
	return response.data;
};

const profile = async () => {
	try {
		const response = await Instance.get(API_URL + profileApi);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const UpdateProfile = async (data) => {
	try {
		const response = await Instance.put(API_URL + UpdateProfileApi, data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const authService = {
	register,
	login,
	logout,
	profile,
	UpdateProfile
};

export default authService;

